import FiveGridLine_FadeLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/FadeLine/FadeLine.skin';


const FiveGridLine_FadeLine = {
  component: FiveGridLine_FadeLineComponent
};


export const components = {
  ['FiveGridLine_FadeLine']: FiveGridLine_FadeLine
};


// temporary export
export const version = "1.0.0"
